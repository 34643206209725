import React from 'react'
import { DateTime } from 'luxon'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import BreadCrumb from '../components/breadcrumb/breadcrumb'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import Time from '../components/time'

const NewsPost = ({ data: { post } }) => {
  return (
    <>
      <SEO
        title={`${post.frontmatter.title} · News`}
        pathname={post.fields.slug}
        article
      />

      <Layout>
        <BreadCrumb
          items={[{ to: '/news/', text: 'News' }]}
          activeItem={post.frontmatter.title}
        />

        <article className='col-lg-8 mx-auto'>
          <h2>{post.frontmatter.title}</h2>

          <h3>
            <small className='text-muted'>
              <Time time={post.frontmatter.date} format={DateTime.DATE_HUGE} />
            </small>
          </h3>

          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
      </Layout>
    </>
  )
}

NewsPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        date: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default NewsPost

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
      }
      fields {
        slug
      }
    }
  }
`
